@use "../styles/theme.scss";

:local(.room-permissions),
:local(.permissions-group) {
  margin-left: 20px;

  & > * {
    margin-top: 12px;
  }

  &:last-child {
    margin-right: 0px;
  }
}

:local(.confirm-revoke-button) {
  display: inline;
  color: theme.$link-color;
}

:local(.label) {
  margin-bottom: 8px;
  color: theme.$red;
  align-self: flex-start;
  font-weight: theme.$font-weight-bold;
  font-size: theme.$font-size-sm;
}
